<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong> Tissue Detail</strong></span>
    </v-card-title>
    <!-- <v-card-title class="justify-center">
      <span class="text-h5"><strong>Tissue Detail</strong></span>
    </v-card-title> -->
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.tissue_id" label="Tissue ID" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3"  class="pl-0">
        <v-text-field v-model="filter.primary_cod" label="Primary COD" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3"  class="pl-0">
        <v-text-field label="Approved Usages" v-model="filter.approved_usages" outlined dense hide-details></v-text-field>
      </v-col>

      <v-col cols="12"  md="3"  class="pl-0 text-right">
        <v-btn color="primary"  class="mr-2" @click="AllSearch()"> Search</v-btn>
        <v-btn color="primary"  @click="AllView()">Reset</v-btn>
      </v-col>
   
    </v-row>

    <v-data-table
      :headers="headers" 
      :items="RecipientDetail"
      :items-per-page="10"
      fixed-header
      height="400"
      class="table-rounded"
      item-key="donor_id"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template #[`item.action`]="{ item }">
          <v-btn color="primary" v-if="item.pdf_path != ''" :href="`https://api.ebsr.in/${item.pdf_path}`" target="_blank"> 
            Print 
          </v-btn>
      </template>
      
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="pt-3 col-12">
            <v-col  sm="6" md="6">
              <p><strong> Donor Information: </strong></p>
            <p>Donor Name: {{ item.donor_name }}</p>
            <p>Donor Age: {{ item.age }}</p>
            <p>Donor Gender: {{ item.gender }}</p> 
            <p>Date-Time of Death: {{ moment.utc(item.date_time_of_death).format("DD-MM-YYYY HH:mm") }}</p>
            <p>Date-Time of in situ: {{ moment.utc(item.date_time_of_in_situ).format("DD-MM-YYYY HH:mm") }}</p>
            </v-col>
            <v-col  sm="6" md="6">
              <p><strong>Serology</strong></p>
            <p >HBsAG: {{ item.HBsAG }}</p>
            <p >HCV: {{ item.HCV }}</p>
            <p >HIV I/II: {{ item.HIV_I_II }}</p>
            <p >Spyhills: {{ item.spyhills }}</p>
            <p >Final Release By: {{ item.final_release_by }}</p>
            <p >Final Release Date-Time: {{ moment.utc(item.final_release_date_time).format("DD-MM-YYYY HH:mm") }}</p>
          </v-col>
          </v-row>
        </td>
    </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>

import AllTissue from '@/service/Tissue.service'
import moment from 'moment'
import { api } from '@/config/config'
import donorService from '@/service/Donor.service'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token

export default {
  data() {
    return {
      moment,
      expanded:[],
      singleExpand: true,
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      permissionData: [],
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      RecipientDetail: [],
      filter: {
        tissue_id: '',
        primary_cod: '',
        approved_usages: '',
      },

      headers: [
        { text: 'Tissue ID', value: 'tissue_id', width: '150px' },
        { text: 'Primary COD', value: 'primary_COD', width: '150px' },
        { text: 'Tissue Type', value: 'tissue_type', width: '150px' },
        { text: 'Autopsy', value: 'autopsy', width: '150px' },
        { text: 'Approved Usages', value: 'approved_usages', width: '150px' },
         { text: 'OPERATE', value: 'action', width: '150px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.AllView()
      },
      deep: true,
    },
  },

  async mounted() {
    this.permission()
    this.AllView()
  },

  methods: {

    async permission() {
      const service = new donorService()
      let response = await service.donorList()
      this.permissionData = response.permissions[8]
    },

    async AllView() {
      this.listLoading = true
      const service = new AllTissue()
      let response = await service.AllList()
      if (response) {
        this.RecipientDetail = response
        this.totallist = this.RecipientDetail.length
        ;(this.filter.tissue_id = ''),
          (this.filter.primary_cod = ''),
          (this.filter.approved_usages = '')
      } else {
        this.RecipientDetail = []
        this.totallist = 0
      }
    },

    async AllSearch() {
      this.listLoading = true
      const data = {
        tissue_id: this.filter.tissue_id,
        primary_cod: this.filter.primary_cod,
        approved_usages: this.filter.approved_usages,
      }

      if(this.filter.tissue_id == '' && this.filter.primary_cod == '' && this.filter.approved_usages == ''){
            this.snackbarText = 'Kindly Enter The Keyword',
              this.snackbar = true
              return;
        }

      try {
        const response = await api.post(`tissueDetails/search_tissue_details`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.length > 0) {
          this.RecipientDetail = response.data
          this.totallist = this.RecipientDetail.length
        } else {
          this.RecipientDetail = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
